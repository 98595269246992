<template>
  <div class="home">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-mobile ordered-mobile">
            <div class="column is-8-mobile is-offset-2-mobile is-4-tablet is-offset-4-tablet has-text-centered-mobile fl-2">
              <figure class="image is-2by1">
                <img src="../assets/logo_mediurs.png" alt="logo mediurs">
              </figure>
            </div>
            <div class="column has-text-right has-text-centered-mobile fl-1">
              <div class="social-icons">
                <a href="https://www.facebook.com/Mediurs/" tile="Facebook"><i
                  class="fab fa-2x fa-facebook"></i></a>
                <a href="https://www.instagram.com/medi_urs/" title="Instagram"><i
                  class="fab fa-2x fa-instagram"></i></a>
                <a href="https://www.youtube.com/channel/UCT5OH14GcRQyw5ZfpamLMfg" title="Youtube"><i
                  class="fab fa-2x fa-youtube"></i></a>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <figure class="image is-1by1">
                <img src="../assets/ebook-ipad_mediurs.png" alt="Ebook-Ipad Mediurs">
              </figure>
            </div>
            <div class="column has-text-left">
              <h1 class="title is-1">Psychische Erste Hilfe:</h1>
              <h2 class="subtitle is-3">Tipps und Tricks, um durch Ausnahmesituationen zu kommen</h2>
              <p>Kindle Edition von Urs Wegerhoff</p>
              <p>Wem würden zum Thema Erste Hilfe schon psychische Erkrankungen in den Sinn kommen? In Zeiten in denen
                Depressionen oder PTBS immer häufiger diagnostiziert werden, ist aber gerade das besonders wichtig.
                Der Autor erklärt anschaulich und leicht verständlich, wie man sich schützen kann und gibt einem
                Massnahmen an die Hand, die ganz im Sinne der ersten Hilfe, jeder einfach durchführen kann.
              </p>
              <br>
              <p>
                <a class="button is-medium is-link"
                   href="https://www.amazon.de/dp/B07HQYDCM8/ref=cm_sw_em_r_mt_dp_U_-A2UBbRF1W7ZT" target="_blank"
                   rel="noreferrer">
                  <span class="icon">
                    <i class="fas fa-tablet-alt"></i>
                  </span>
                  <span>Kindle ebook</span>
                </a>
                &nbsp;
                <a class="button is-medium is-success"
                   href="https://www.amazon.de/dp/1724107739/ref=cm_sw_em_r_mt_dp_U_bA2UBbSP6TNDK" target="_blank"
                   rel="noreferrer">
                  <span class="icon">
                    <i class="fas fa-book"></i>
                  </span>
                  <span>Taschenbuch</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <figure class="image is-1by1">
              <img src="../assets/foto_urs_wegrhoff_mediurs.jpg" alt="Foto Urs Wegrhoff Mediurs">
            </figure>
          </div>
          <div class="column has-text-left">
            <h1 class="title is-1 has-text-primary">Über den Autor:</h1>
            <h2 class="subtitle is-3 has-text-primary">ICH BIN URS.DER MEDI URS.</h2>
            <p>Ich bin Examinierter Krankenpfleger, Rettungssanitäter, Technischer Redakteur im Hauptberuf.
              Ich beschäftige mich seit 1986 ernsthaft mit den Themen Erste Hilfe, Notfallmedizin, Rettungsdienst,
              Feuerwehr, Katastrophen – und Bevölkerungsschutz. Ich habe mehrere Positionen in verschiedenen
              Hilfsorganisationen durchlaufen und war zum Teil im Ehrenamt, zum Teil in Hauptberuf in der Branche
              tätig.</p>
            <br>
            <p>
              <a href="https://mediurs.de/" class="button is-primary">Mehr Info</a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <figure class="image is-1by1">
              <img src="../assets/e-book-psychische-erste-hilfe-mediurs.jpg"
                   alt="e-book-psychische-erste-hilfe-mediurs">
            </figure>
          </div>
          <div class="column has-text-left">
            <h1 class="title is-1 has-text-primary">Über das Buch:</h1>
            <h2 class="subtitle is-3 has-text-primary">Psychische Erste Hilfe</h2>
            <p>Wir alle wissen, was zu tun ist, wenn sich ein Unfall ereignet oder wenn auf der Straße jemand
              kollabiert. Schon Kinder lernen die Notrufnummer auswendig, jeder Autofahrer hat einen Erste Hilfe Kurs
              absolviert. Notärzte, Sanitäter und professionelle Retter wie Feuerwehrleute sind darin geschult,
              innerhalb von Sekunden zu entscheiden, was getan werden muss, um eine Katastrophe einzudämmen und
              körperliche Gefahr zu verhindern. Aber wie sieht es mit der Seele aus?
              Ausnahmesituationen wie Unfälle oder Gewalterfahrung lösen bei jedem Menschen Schockzustände und Ängste
              aus, die ebenso der Hilfe bedürfen wie körperliche Verletzungen. Auch psychische Erkrankungen entstehen
              oft so langsam und schleichend, dass sie erst bei einer Eskalation wie einer Panikattacke am Arbeitsplatz
              offenbar werden. Professionelle Retter sollten also auch in psychologischer Betreuung geschult werden und
              auch für Laien ist es wichtig, psychische Erste Hilfe leisten zu können.</p>
            <br>
            <p>
              <a class="button is-medium is-link"
                 href="https://www.amazon.de/dp/B07HQYDCM8/ref=cm_sw_em_r_mt_dp_U_-A2UBbRF1W7ZT" target="_blank"
                 rel="noreferrer">
                  <span class="icon">
                    <i class="fas fa-tablet-alt"></i>
                  </span>
                <span>Kindle ebook</span>
              </a>
              &nbsp;
              <a class="button is-medium is-success"
                 href="https://www.amazon.de/dp/1724107739/ref=cm_sw_em_r_mt_dp_U_bA2UBbSP6TNDK" target="_blank"
                 rel="noreferrer">
                  <span class="icon">
                    <i class="fas fa-book"></i>
                  </span>
                <span>Taschenbuch</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
  export default {
    name: 'home'
  }
</script>

<style>
  .social-icons a {
    color: white;
    padding: .25em;
  }

  @media screen and (max-width: 768px) {
    .ordered-mobile {
      display: flex;
      flex-flow: column;
    }

    .ordered-mobile .fl-1 {
      order: 1;
    }

    .ordered-mobile .fl-2 {
      order: 2;
    }
  }
</style>
